import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "getDatesBtn",
    "transitDays",
    "transitValue",
    "shipDate",
    "hideUpdateDatesModal",
    "shipperNumberBtn",
    "modal",
    "shipperNumber",
    "shipMethod",
    "deliveryDate",
    "shipmentId",
    "submitDatesBtn",
    "shippingWarning"
  ];
  

  getDates() {
    this.submitDatesBtnTarget.disabled = true;

    const transit = this.transitValueTarget;

    const saturdayOptions = ["UPS Next Day Air Early", "UPS Next Day Air", "UPS 2nd Day Air", "FedEx First Overnight", "FedEx Priority Overnight"]
    const date = new Date(this.deliveryDateTarget.value.split("-").join(", "))

    const saturdayMismatch = [0, 6].includes(date.getUTCDay()) && !saturdayOptions.includes(this.shipMethodTarget.value)

    if (saturdayMismatch) {
      this.shippingWarningTarget.classList.remove("d-none")
      this.getDatesBtnTarget.classList.add("d-none")
    } else if (this.deliveryDateTarget.value && this.shipMethodTarget.value && this.transitDaysTarget.classList.contains("d-none")) {
      this.shippingWarningTarget.classList.add("d-none")
      this.getDatesBtnTarget.classList.remove('d-none');
      this.getDatesBtnTarget.classList.add('btn');
      this.getDatesBtnTarget.classList.add('btn-primary');
    } else if (
      this.deliveryDateTarget.value &&
      this.shipMethodTarget.value &&
      !this.transitDaysTarget.classList.contains("d-none") &&
      transit.value
    ) {
      this.shippingWarningTarget.classList.add("d-none")
      this.getDatesBtnTarget.classList.remove('d-none');
      this.getDatesBtnTarget.classList.add('btn');
      this.getDatesBtnTarget.classList.add('btn-primary');
    }

    // if (this.deliveryDateTarget.value && this.shipMethodTarget.value && this.transitDaysTarget.classList.contains("d-none")) {
    //   this.getDatesBtnTarget.classList.remove('d-none');
    //   this.getDatesBtnTarget.classList.add('btn');
    //   this.getDatesBtnTarget.classList.add('btn-primary');
    // } else if (
    //   this.deliveryDateTarget.value &&
    //   this.shipMethodTarget.value &&
    //   !this.transitDaysTarget.classList.contains("d-none") &&
    //   transit.value
    // ) {
    //   this.getDatesBtnTarget.classList.remove('d-none');
    //   this.getDatesBtnTarget.classList.add('btn');
    //   this.getDatesBtnTarget.classList.add('btn-primary');
    // }

    // const shipmentId = this.shipmentIdTarget.value;
    // // TODO: This event is currently handled by shipping_options_controller, but it doesn't do anything with.
    // // The intention is to have this hide existing options while new ones are retrieved.
    // this.element.dispatchEvent(new CustomEvent("shipping-options-update", { detail: { shipmentId: shipmentId }, bubbles: true }));
    // // this.submitDatesBtnTarget.disabled = false;
  }

  setTransitDays(onLoad) {
    if (onLoad == "load") {
      const shipMethod = this.shipMethodTarget.value;
      if (!shipMethod.includes("UPS")) {
        $(this.transitDaysTarget).removeClass("d-none");
        this.submitDatesBtnTarget.disabled = true;
        
      } else {
        $(this.transitDaysTarget).addClass("d-none");
        $(this.transitValueTarget).val("");
      }
    } else {
      const shipMethod = this.shipMethodTarget.value;
      if (!shipMethod.includes("UPS")) {
        $(this.transitDaysTarget).removeClass("d-none");
        this.submitDatesBtnTarget.disabled = true;

      } else {
        $(this.transitDaysTarget).addClass("d-none");
        $(this.transitValueTarget).val("");
        this.getDates();
      }
    }
  }

  submitDates() {
    const estDeliveryDateInput = this.deliveryDateTarget.value;

    if (estDeliveryDateInput) {
      this.shipDateTarget.disabled = false;
      this.hideUpdateDatesModalTarget.click();
    } else {
      alert("Delivery date cannot be blank");
    }
  }

  showShipperNumber(e) {
    const num = e.target;
    const newVal = $(num).val();
    const oldVal = $(num)[0].defaultValue;
    if (newVal != oldVal) {
      $(this.shipperNumberBtnTarget).removeClass("d-none");
      this.shipperNumberBtnTarget.disabled = false
    } else {
      $(this.shipperNumberBtnTarget).addClass("d-none");
      this.shipperNumberBtnTarget.disabled = true
    }
  }

  connect() {
    this.setTransitDays("load");
    $(this.hideUpdateDatesModalTarget).click();
    $("body").removeClass("modal-open");
    $("body").css("padding-right", "0px");
    $(".modal-backdrop").remove();
  }
}
