import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["title", "deliveryDate", "cost", "shippingMethod", "shippingCard", "shipEarlyCard", "shipEarly", "shippingOption"];

  update(e) {
    e.preventDefault();
    const deliveryDate = this.deliveryDateTarget.dataset.deliverydate;
    const cost = this.costTarget.dataset.cost;
    const shippingMethod = this.shippingMethodTarget.innerHTML;
    const title = this.titleTarget.innerHTML;
    //const shipEarly = this.shipEarlyTarget.dataset.shipearly;
    const id = this.shippingCardTarget.dataset.shipmentid;

    document.getElementById(`shipping_method_option_${id}`).value = shippingMethod;
    document.getElementById(`delivery_date_option_${id}`).value = deliveryDate;
    document.getElementById(`flexible_shipping_${id}`).value = title == "FREE" || title == "FLEXIBLE" ? true : false;
    document.getElementById(`shipping_cost_option_${id}`).value = cost;
    //document.getElementById(`can_ship_early_option_${id}`).value = shipEarly;

    document.getElementById(`update_shipment_${id}`).click();
  }

  updateShipEarly(e) {
    const id = this.shipEarlyCardTarget.dataset.shipmentid;
    e.preventDefault();
    document.getElementById(`update_shipment_ship_early_${id}`).click();
  }

  connect() {
    if (!this.targets.findTarget("shippingOption")) return;
    if (this.shippingOptionTarget.checked &&  this.shippingOptionTarget.getAttribute('data-options-updated')) {
      const updateEvent = new Event('click', { bubbles: true });
      this.element.dispatchEvent(updateEvent);
    }
  }

}
